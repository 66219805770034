import React from 'react'
import { Box,  Typography } from "@mui/material";
import virginMedium from "./assets/customers/130px/virgin130px.jpg"
import virginSm from "./assets/customers/90px/VirginMobile_90x90.jpg"
import movistarMedium from "./assets/customers/130px/movistar130px.png"
import movistarSm from "./assets/customers/90px/movistar_90x90.png"
import claroMedium from "./assets/customers/130px/claro130px.png"
import claroSm from "./assets/customers/90px/claro_90x90.png"
import tigoMedium from "./assets/customers/130px/tigo130px.png"
import tigoSm from "./assets/customers/90px/tigo_90x90.png"
import entelMedium from "./assets/customers/130px/entel130px.png"
import entelSm from "./assets/customers/90px/entel_90x90.png"
import womMedium from "./assets/customers/130px/wom130px.png"
import womSm from "./assets/customers/90px/wom_90x90.png"

import CustomerCard from './CustomerCard';
import djiMd from "./assets/partners/170px/dji.png"
import djiSm from "./assets/partners/100px/dji.png"
import temsMd from "./assets/partners/170px/tems.png"
import temsSm from "./assets/partners/100px/tems.png"
import pctelMd from "./assets/partners/170px/pctel.jpg"
import pctelSm from "./assets/partners/100px/pctel.jpg"
import teocoMd from "./assets/partners/170px/teoco.png"
import teocoSm from "./assets/partners/100px/teoco.png"
import nemoMd from "./assets/partners/170px/nemo.png"
import nemoSm from "./assets/partners/100px/nemo.png"
import anritsuMd from "./assets/partners/170px/anritzu.jpg"
import anritsuSm from "./assets/partners/100px/anritsu.jpg"
import garminMd from "./assets/partners/170px/garmin.png"
import garminSm from "./assets/partners/100px/garminm.png"
import infoVistaMd from "./assets/partners/170px/infoVista.jpg"
import infoVistaSm from "./assets/partners/100px/infoVista.jpg"
import keySightMd from "./assets/partners/170px/keysight.png"
import keySightSm from "./assets/partners/100px/keysight.png"
import luxCartaMd from "./assets/partners/170px/luxCarta.jpg"
import luxCartaSm from "./assets/partners/100px/luxCarta.jpg"

import imageBg from "./assets/custommers.jpg"

import { lightBlue } from '@mui/material/colors';
const verde = lightBlue[900]; // #f44336

const imgCust = [
  {
    label: "virginMobile",
    imgPath: virginMedium,
    imgSmall: virginSm,
  },
  {
    label: "movistar",
    imgPath: movistarMedium,
    imgSmall: movistarSm,
  },
  {
    label: "claro",
    imgPath: claroMedium,
    imgSmall: claroSm,
  },
  {
    label: "tigo",
    imgPath: tigoMedium,
    imgSmall: tigoSm,
  },
  {
    label: "entel",
    imgPath: entelMedium,
    imgSmall: entelSm,
  },
  {
    label: "Wom",
    imgPath: womMedium,
    imgSmall: womSm,
  }
]

const imgParth = [
  {
    label: "dji",
    imgPath: djiMd,
    imgSmall: djiSm,
  },
  {
    label: "tems",
    imgPath: temsMd,
    imgSmall: temsSm,
  },
  {
    label: "pctel",
    imgPath: pctelMd,
    imgSmall: pctelSm,
  },
  {
    label: "teoco",
    imgPath: teocoMd,
    imgSmall: teocoSm,
  },
  {
    label: "nemo",
    imgPath: nemoMd,
    imgSmall: nemoSm,
  },
  {
    label: "anritsu",
    imgPath: anritsuMd,
    imgSmall: anritsuSm,
  },
  {
    label: "garmin",
    imgPath: garminMd,
    imgSmall: garminSm,
  },
  {
    label: "infoVista",
    imgPath: infoVistaMd,
    imgSmall: infoVistaSm,
  },
  {
    label: "keysight",
    imgPath: keySightMd,
    imgSmall: keySightSm,
  },
  {
    label: "luxCarta",
    imgPath: luxCartaMd,
    imgSmall: luxCartaSm,
  }

]

const Customers = () => {
  return (
    <div
      style={{
        //backgroundImage: `url(https://res.cloudinary.com/dzkp92nfz/image/upload/v1669854756/evotech/Custommers/custommers_gp9yti.jpg)`,
        backgroundImage: `url(${imageBg})`,
        width: "100%",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}>

      <div>
        <Typography gutterBottom variant="h4" 
          component="div"
          sx={{
            fontSize:{xs:"1.8em", sm:"2.0em", md:"2.6em", lg:"3.4em"},
            fontWeight:"bold",
            mr:{sm:"42px", lg: "260px"},
            pl:{xs:"10px", sm:"0px", md:"100px", lg:"86px"},
            pt: {xs:"30px", sm:"40px", lg:"60px"},
            pr: {xs:"45px", sm:"150px", md:"324px", lg:"250px"},
            textAlign:{xs:"center"},
            color: verde
          }}
        >
          Confían en Nosotros
        </Typography>            

        <Box 
          sx={{
            maxWidth: {xs:"350px", sm:"455px", md:"500px", lg:"550px"},
            m: {xs:'0 auto', },
            pr:{sm:"66px", md:"210px", lg:"360px"},
            opacity:".8",
            backgroundColor: "transparent",
            display:"flex",
            justifyContent:"start",
            flexWrap:{xs:"wrap"},
          }}>
              {
                imgCust.map(imag => (
                  <CustomerCard imag={imag} key={imag.label}/>
                ))
              }
        </Box>

        <Typography gutterBottom variant="h4" 
          component="div"
          sx={{
            fontSize:{xs:"1.8em", sm:"2.3em", md:"2.4em", lg:"3em"},
            fontWeight:"bold",
            mr:{sm:"0px", lg: "260px"},
            pr:{xs:"40px", sm:"194px", md:"256px", lg:"160px"},
            pt: {xs:"42px", sm:"40px", lg:"80px"},
            textAlign:{xs:"center"},
            color: "darkblue"
          }}
        >
          Nuestro Equipo
        </Typography>

        <Box 
          sx={{
            maxWidth: {xs:"300px", sm:"429px", md:"700px", lg:"720px"},
            m:{ xs:"0 auto", sm:'auto'},
            backgroundColor: "transparent",
            pr:{sm:"120px", lg:"300px"},
            pl:{xs:"30px", sm:"0px"},
            display:{ xs: "flex" },
            pt:{xs:"30px", md:"35px", lg:"40px"},
            pb:"30px",
            opacity:".78",
            flexWrap: {xs:"wrap", sm:"no wrap"},
          }}>
              {
                imgParth.map(imag => (
                  <CustomerCard imag={imag} key={imag.label}/>
                ))
              }
        </Box>            
      </div>
    </div>
  )
}

export default Customers
